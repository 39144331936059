import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/GraphQLErrorList";
import FigList from "../components/FigList";
import SEO from "../components/SEO";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import LayoutContainer, { PageTypes } from "../containers/LayoutContainer";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }

    figs: allSanityFig(
      sort: { fields: [order], order: ASC }
      filter: {
        slug: { current: { ne: null } }
        number: { ne: null }
        # filter on is index page
        # categories: { elemMatch: { title: { eq: "Digital" } } }
      }
    ) {
      edges {
        node {
          id
          number
          title
          inactive
          year
          figGroup {
            id
          }
          slug {
            current
          }
          isVideoOnIndex
          indexVideo {
            vimeoId
          }
          indexVideoThumb {
            asset {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 2400)
              _id
            }
            alt
          }
          mainImage {
            # crop {
            #   _key
            #   _type
            #   top
            #   bottom
            #   left
            #   right
            # }
            # hotspot {
            #   _key
            #   _type
            #   x
            #   y
            #   height
            #   width
            # }
            # asset {
            #   gatsbyImageData(layout: FULL_WIDTH, fit: FILLMAX)
            #   _id
            # }
            asset {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 2400)
              _id
            }
            # ...ImageWithPreview
            alt
          }
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;
  if (errors) {
    return <GraphQLErrorList errors={errors} />;
  }

  const site = (data || {}).site;

  const figNodes =
    data && data.figs && mapEdgesToNodes(data.figs).filter(filterOutDocsWithoutSlugs);
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <>
      <SEO title={site.title} />
      <LayoutContainer pageType={PageTypes.List}>
        {figNodes && figNodes.length > 0 && <FigList nodes={figNodes} />}
      </LayoutContainer>
    </>
  );
};

export default IndexPage;
